<template>
  <el-main>
    <div class="title">订单信息</div>
    <div class="order_title">
      <p>订单编号：{{ order_info.ordersn }}</p>
    </div>
    <div class="stepsBox">
      <div class="stepItem" v-for="(item, index) in stepList" :key="index">
        <div :class="['stepsTitle', item.time ? 'activeStepColor' : '']">{{ item.title }}</div>
        <div :class="['stepCir', item.time ? 'activeStepBgColor' : '']">{{ index + 1 }}</div>
        <div class="stepTime">{{ item.time ? getDateformat(item.time) : '--' }}</div>
        <div :class="['stepLine', stepList[index + 1] && stepList[index + 1].time ? 'activeStepBgColor' : '']" v-if="index !== stepList.length - 1"></div>
      </div>
    </div>
    <div class="orderStatus">
      <div>
        订单状态：
        {{
          order_info.status === -20
            ? '后台取消'
            : order_info.status === -10
            ? '回收员取消'
            : order_info.status === 20
            ? '已接单'
            : order_info.status === 30
            ? '待上门'
            : order_info.status === 40
            ? '待入账'
            : order_info.status === 50
            ? '已完成'
            : order_info.status === 0
            ? '已取消'
            : '待接单'
        }}
      </div>
      <div class="tips" v-if="order_info.status === -20">后台取消备注:{{ order_info.remark || '--' }}</div>
      <div class="tips" v-if="order_info.status === -10">回收员取消备注:{{ order_info.remark || '--' }}</div>
      <div class="tips" v-if="order_info.status >= 0">用户下单备注:{{ order_info.usernote || '--' }}</div>
    </div>
    <div class="title">预约人信息</div>
    <table v-if="order_info.address">
      <thead>
        <tr>
          <th>预约人昵称</th>
          <th>预约人姓名</th>
          <th>预约时间</th>
          <th>预约人电话</th>
          <th>收货地址</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ order_info.nick_name }}</td>
          <td>{{ order_info.address.name }}</td>
          <td>{{ order_info.order_door_time }}</td>
          <td>{{ order_info.address.mobile }}</td>
          <td>{{ order_info.address.address }}</td>
        </tr>
      </tbody>
    </table>
    <div class="title">回收信息</div>
    <table v-if="order_info.address">
      <thead>
        <tr>
          <th>回收信息</th>
          <th>规格</th>
          <th>用户单价</th>
          <th>回收站支付单价</th>
          <th>数量</th>
          <th>用户单价小计</th>
          <th>回收站支付小计</th>
          <th>用户订单金额</th>
          <th>能量值</th>
          <th>回收站金额</th>
          <th>回收站改价金额</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in order_info.goods" :key="index">
          <td>{{ item.goods_name }}</td>
          <td>{{ item.name }}</td>
          <td>￥{{ item.cost_price }}</td>
          <td>￥{{ item.recycle_price }}</td>
          <td>x{{ item.num + item.unit }}</td>
          <td>￥{{ item.person_total_price }}</td>
          <td>￥{{ item.recycle_total_price }}</td>
          <td v-if="index == 0" :rowspan="order_info.goods.length">￥{{ order_info.price }}</td>
          <td v-if="index == 0" :rowspan="order_info.goods.length">{{ order_info.energy }}</td>
          <td v-if="index == 0" :rowspan="order_info.goods.length">￥{{ order_info.recycle_price }}</td>
          <td v-if="index == 0" :rowspan="order_info.goods.length">￥{{ order_info.recycle_bin_price }}</td>
        </tr>
      </tbody>
    </table>
    <div class="title">接单人信息信息</div>
    <table v-if="order_info.address">
      <thead>
        <tr>
          <th>接单人昵称</th>
          <th>接单人姓名</th>
          <th>接单人电话</th>
          <th>接单时间</th>
          <th>所属区域</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="order_info.recycler">
          <td>{{ order_info.recycler.nick_name }}</td>
          <td>{{ order_info.recycler.name }}</td>
          <td>{{ order_info.recycler.tel }}</td>
          <td>{{ order_info.order_time ? getDateformat(order_info.order_time) : '' }}</td>
          <td>{{ order_info.recycler.address_info }}</td>
        </tr>
        <tr v-else>
          <td>--</td>
          <td>--</td>
          <td>--</td>
          <td>--</td>
          <td>--</td>
        </tr>
      </tbody>
    </table>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate.js';
export default {
  data() {
    return {
      getDateformat,
      order_id: null,
      order_info: {},
      stepList: [],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.order_id = this.$route.query.id;
      this.getOrderInfo();
    }
  },
  methods: {
    getOrderInfo() {
      this.$axios
        .post(this.$api.O2O.order.orderInfo, {
          id: this.order_id,
        })
        .then(res => {
          if (res.code === 0) {
            this.order_info = res.result;
            this.stepList.push({ title: '下单时间', time: res.result.create_time });
            if (this.order_info.status === -20 || this.order_info.status === -10) {
              let str = this.order_info.status === -20 ? '后台取消' : '用户取消';
              this.stepList.push({ title: str, time: res.result.cancle_time });
            }
            this.stepList.push({ title: '接单时间', time: res.result.order_time });
            this.stepList.push({ title: '上门时间', time: res.result.door_time });
            this.stepList.push({ title: '完成时间', time: res.result.finish_time });
            console.log(this.stepList);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 取消订单
    cancleOrder(data) {
      this.$confirm('此操作将取消该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.O2O.order.cancle, { id: data.id });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('已成功取消订单');
            this.getOrderInfo();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  min-width: 950px;
  background-color: #fff;
  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .order_title {
    display: flex;
    height: 47px;
    background: #f0f0f5;
    align-items: center;
    padding-left: 24px;
    p {
      font-size: 14px;
      margin-right: 40px;
    }
  }
  .stepsBox {
    user-select: none;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    .stepItem {
      width: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 40px;
      position: relative;
      .stepsTitle {
        font-size: 14px;
      }
      .stepCir {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #c8c8cc;
        border-radius: 50%;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
      }

      .stepTime {
        font-size: 12px;
      }
      .stepLine {
        height: 4px;
        width: 90px;
        background: #f0f0f5;
        position: absolute;
        left: 95px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .orderStatus {
    padding: 30px 0;
    background: #f0f0f5;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .tips {
      margin-top: 10px;
      font-size: 12px;
      color: #333;
    }
  }

  table {
    width: 100%;
    border: 1px solid #f8f9fa;
    margin-bottom: 20px;
    font-size: 14px;
    thead {
      background: #f8f9fa;
    }
    tr {
      th,
      td {
        padding: 15px;
        text-align: center;
      }
    }
  }
}
.activeStepColor {
  color: #1467ff;
}

.activeStepBgColor {
  background-color: #1467ff !important;
}
</style>
